import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~7],
		"/admin/keywords": [~9,[2]],
		"/admin/linkedin-posts": [~10,[2]],
		"/admin/outbound-clicks": [~11,[2]],
		"/admin/products": [~12,[2]],
		"/admin/products/insertion": [~14,[2]],
		"/admin/products/insertion/add": [16,[2]],
		"/admin/products/insertion/[id]": [~15,[2]],
		"/admin/products/[id]": [~13,[2]],
		"/admin/seo/comparisons": [~17,[2]],
		"/admin/seo/products": [~18,[2]],
		"/admin/seo/products/[id]": [~19,[2]],
		"/admin/stacks": [~20,[2]],
		"/admin/stacks/authors": [~22,[2]],
		"/admin/stacks/order": [~23,[2]],
		"/admin/stacks/tags": [~24,[2]],
		"/admin/stacks/[id]": [~21,[2]],
		"/admin/website-visitor": [25,[2]],
		"/catalog": [~26],
		"/compare/[products]": [~27],
		"/compare/[products]/personalised": [~28],
		"/discover": [29],
		"/(seo)/product/[slug]": [~8],
		"/roast": [30],
		"/roast/add": [32],
		"/roast/[roastId]": [~31],
		"/stacks": [~33,[3]],
		"/stacks/add": [36,[3,5]],
		"/stacks/add/profile": [~38,[3,5]],
		"/stacks/add/[stackId]/products": [~37,[3,5]],
		"/stacks/profile": [~39,[3]],
		"/stacks/tag/[slug]": [~40,[3]],
		"/stacks/tool/[tool]": [~41,[3,6]],
		"/stacks/[author]": [~34,[3,4]],
		"/stacks/[author]/[stack]": [~35,[3,4]],
		"/style-guide": [42],
		"/trends": [~43]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';